<template>
  <div>
    <el-form
      label-width="140px"
      ref="budgetForm"
      :model="budgetInfo"
    >
      <el-form-item
        label="广告系列预算优化："
        class="blod"
      >
        <el-switch
          v-model="budgetInfo.is_cbo"
          :disabled="!canEdit || budgetInfo.smart_promotion_type == 'AUTOMATED_SHOPPING_ADS'"
          @change="changeCbo"
        ></el-switch>
        <p class="tips">
          启用广告系列预算优化(CBO)，系统会将广告系列预算根据广告组的实时表现分配给广告组，以获得更多转化。
        </p>
      </el-form-item>
      <el-form-item
        :label="`广告${budgetInfo.is_cbo ? '系列' : '组'}预算：`"
        class="blod"
        prop="budget"
        v-show="(budgetInfo.is_cbo && level == 'campaign') || (!budgetInfo.is_cbo && level == 'adset')"
        :rules="[
          { required: true, message: '预算不能为空', trigger: ['blur'] },
          { type: 'number', min: 1, message: '预算最小值为1' },
        ]"
      >
        <el-input
          v-model="budgetInfo.budget"
          @blur="getBudgetMsg"
          @change="(v) => setBudget(`${budget_type}_budget`, v)"
          type="number"
        >
          <el-select
            v-model="budget_type"
            slot="prepend"
            placeholder="请选择"
            disabled
            @change="selectBudgetType"
          >
            <el-option
              label="单日预算"
              value="daily"
            ></el-option>
            <el-option
              label="总预算"
              value="lifetime"
            ></el-option>
          </el-select>
          <el-button slot="append">{{ currencyName }}</el-button>
        </el-input>
      </el-form-item>
      <el-form-item
        label="广告组花费限额："
        v-show="budgetInfo.is_cbo && level == 'adset'"
      >
        <el-checkbox v-model="adsChecked">广告组花费限额</el-checkbox>
        <div
          class="dailySpendCap"
          v-show="adsChecked"
        >
          <div class="l">
            <p>{{ campaign_budget_type == 'daily' ? '单日' : '总' }}花费下限</p>
            <el-input
              v-if="campaign_budget_type == 'daily'"
              placeholder="请输入单日花费下限"
              type="number"
              v-model="budgetInfo.daily_min_spend_target"
              :min="0"
              @change="getBudgetMsg"
              ><template slot="append">$</template></el-input
            >
            <el-input
              v-else
              placeholder="请输入总花费下限"
              type="number"
              v-model="budgetInfo.lifetime_min_spend_target"
              :min="0"
              @change="getBudgetMsg"
              ><template slot="append">$</template></el-input
            >
            <span>我们无法保证花费金额与花费下限完全吻合</span>
          </div>
          <div class="r">
            <p>{{ campaign_budget_type == 'daily' ? '单日' : '总' }}花费上限</p>
            <el-input
              v-if="campaign_budget_type == 'daily'"
              placeholder="请输入单日花费上限"
              type="number"
              v-model="budgetInfo.daily_spend_cap"
              :min="0"
              @change="getBudgetMsg"
              ><template slot="append">$</template></el-input
            >
            <el-input
              v-else
              placeholder="请输入总花费上限"
              type="number"
              v-model="budgetInfo.lifetime_spend_cap"
              :min="0"
              @change="getBudgetMsg"
              ><template slot="append">$</template></el-input
            >
            <span>你的花费不会超过这个金额</span>
          </div>
        </div>
      </el-form-item>
      <!-- 开启广告系列预算优化的时候展示广告组的出价策略 -->
      <el-form-item
        label="投放优化目标："
        v-if="level == 'campaign' && budgetInfo.is_cbo"
      >
        <el-select
          v-model="budgetInfo.optimization_goal"
          placeholder="请选择"
          @change="setTarget"
          :disabled="!canEdit"
        >
          <el-option
            :label="item.desc"
            :value="item.key"
            v-for="(item, index) in optimizationGoals"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="budgetInfo.is_cbo && level == 'campaign'"
        :label="`广告系列竞价策略：`"
      >
        <el-radio-group
          v-model="budgetInfo.bid_strategy"
          @change="changeBidStrategy"
          :disabled="!canEdit"
        >
          <el-radio
            :label="item.key"
            v-for="(item, i) in bidStrategyList"
            :key="i"
            >{{ item.desc }}</el-radio
          >
        </el-radio-group>
        <p class="tips">需要在广告组中设置出价金额才可保存</p>
      </el-form-item>
      <!-- 广告受益方 -->
      <!-- <el-form-item label="广告受益方：">
                <el-input
                    v-model="budgetInfo.dsa_beneficiary"
                    placeholder="请输入广告收益方"
                    @change="getBudgetMsg"
                ></el-input>
                <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
                >
                <p slot="content">
                    欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
                </p>
                <span class="tips">欧盟必填</span>
                </el-tooltip>
            </el-form-item> -->
      <!-- 广告受益方 -->
      <!-- <el-form-item label="广告赞助方：">
                <el-input
                    v-model="budgetInfo.dsa_payor"
                    placeholder="请输入广告赞助方"
                    @change="getBudgetMsg"
                ></el-input>
                <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
                >
                    <p slot="content">
                        欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
                    </p>
                    <span class="tips">欧盟必填</span>
                </el-tooltip>
            </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  // type = draft 表示从草稿箱进入，编辑预算的时候要兼容已上传和未上传的情况
  props: ['budgetMsg', 'level', 'num', 'copy', 'isCreatTarget', 'params', 'type'],
  data() {
    return {
      budgetInfo: {},
      budget_type: '',
      adsChecked: false,
      campaign_budget_type: '',
    };
  },
  computed: {
    ...mapState('initData', ['initData']),
    ...mapGetters('initData', ['getOptimizationGoals', 'getBidStrategyList']),
    currencyName() {
      return this.$store.getters.currentUiUnit.name;
    },
    optimizationGoals() {
      return this.getOptimizationGoals(this.budgetInfo.objective);
    },
    bidStrategyList() {
      return this.getBidStrategyList({
        is_cbo: this.budgetInfo.is_cbo,
        objective: this.budgetInfo.objective,
        optimization_goal: this.budgetInfo.optimization_goal,
        smart_promotion_type: this.budgetInfo.smart_promotion_type,
      });
    },
    paramsForm() {
      return this.params || {};
    },
    // 竞价策略是否展示
    canEdit() {
      let flag = false;
      if (
        (this.copy &&
          (this.params?.is_create_target || this.params?.target_obj_level == 'ACCOUNT') &&
          this.level == 'campaign') ||
        (this.type == 'draft' && this.level == 'campaign')
      ) {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
  },
  methods: {
    setTarget(v) {
      // 修改优化目标，设置竞价策略默认值，同时修改广告组层级优化目标
      this.changeBidStrategy('LOWEST_COST_WITHOUT_CAP');
      this.$emit('changeTarget', v);
    },
    getBudgetMsg() {
      // this.$refs.budgetForm.validateField('budget',res=>{
      //     console.log(res,'budget');
      // })
      let obj = {};
      obj[this.budget_type + '_budget'] = this.budgetInfo.budget
        ? this.$store.getters.toBaseMoney(this.budgetInfo.budget)
        : 0;
      if (this.budget_type == 'daily') {
        obj.lifetime_budget = null;
      } else if (this.budget_type == 'lifetime') {
        obj.daily_budget = null;
      }
      let info = { ...this.budgetInfo, ...obj };
      if (this.budgetInfo.is_cbo) {
        if (this.campaign_budget_type == 'daily') {
          info.daily_min_spend_target = info.daily_min_spend_target
            ? Number((info.daily_min_spend_target * 100).toFixed(2))
            : null;
          info.daily_spend_cap = info.daily_spend_cap ? Number((info.daily_spend_cap * 100).toFixed(2)) : null;
        } else {
          info.lifetime_min_spend_target = info.lifetime_min_spend_target
            ? Number((info.lifetime_min_spend_target * 100).toFixed(2))
            : null;
          info.lifetime_spend_cap = info.lifetime_spend_cap ? Number((info.lifetime_spend_cap * 100).toFixed(2)) : null;
        }
      }
      console.log(info);
      this.$emit('getBudgetMsg', info);
    },
    selectBudgetType() {
      this.getBudgetMsg();
    },
    // 修改左侧树数据
    setBudget(key, value) {
      this.$emit('setBudget', key, value);
    },
    // 修改广告竞价策略
    changeBidStrategy(v) {
      this.budgetInfo.bid_strategy = v;
      this.getBudgetMsg();
      this.$emit('changeBidStrategy', v);
    },
    // 是否开启广告系列预算优化
    changeCbo(v) {
      // 开启系列预算优化
      if (v) {
        this.budgetInfo.budget = 40;
        this.setTarget('OFFSITE_CONVERSIONS');
        this.$set(this.budgetInfo, 'bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
        this.$set(this.budgetInfo, 'optimization_goal', 'OFFSITE_CONVERSIONS');
        this.changeBidStrategy('LOWEST_COST_WITHOUT_CAP');
        this.budget_type = 'daily';
      } else {
        this.budgetInfo.budget = null;
        this.$set(this.budgetInfo, 'bid_strategy', null);
        this.budget_type = 'daily';
      }
      this.getBudgetMsg();
      this.$emit('changeCbo', v);
    },
  },
  watch: {
    budgetMsg: {
      handler(val) {
        this.budgetInfo = JSON.parse(JSON.stringify(this.budgetMsg));
        this.budget_type = this.budgetInfo.daily_budget
          ? 'daily'
          : this.budgetInfo.lifetime_budget
          ? 'lifetime'
          : 'daily';
        this.campaign_budget_type = this.budgetInfo.daily_budget_campaign ? 'daily' : 'lifetime';
        this.$set(
          this.budgetInfo,
          'budget',
          this.budgetInfo.daily_budget || this.budgetInfo.lifetime_budget
            ? this.$store.getters.toFormatedMoney(this.budgetInfo.daily_budget || this.budgetInfo.lifetime_budget).value
            : 0,
        );
        if (
          this.budgetInfo.daily_min_spend_target ||
          this.budgetInfo.daily_spend_cap ||
          this.budgetInfo.lifetime_spend_cap ||
          this.budgetInfo.lifetime_min_spend_target
        ) {
          this.adsChecked = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss"></style>
