<template>
  <div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(nameList)"
      >
        <span class="lebel">名称：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(nameList)">
        <el-col :span="20">
          <name
            :label-width="labelWidth"
            :nameInfo="nameMsg"
            @getNameMsg="getNameMsg"
          ></name>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="nameMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <p
        class="baseName"
        v-if="!isNotMix(statusList)"
      >
        <span class="lebel">状态：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(statusList)">
        <el-col :span="20">
          <status
            :label-width="labelWidth"
            :statusInfo="statusMsg"
            @getStatusMsg="getStatusMsg"
          ></status>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="statusMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="targetingItem">
      <!-- <p class="baseName" v-if="currentSelect.length>1">
                <span class="lebel">预算：</span>
                <span>
                    <el-button type="text" size="mini">预算暂不支持混合编辑</el-button>
                </span>
            </p>
            <el-row  v-else >
                <el-col :span="20">
                    <budget :label-width="labelWidth"  :budgetMsg="budgetMsg" @getBudgetMsg="getBudgetMsg" level="campaign"></budget>
                </el-col>
            </el-row> -->
      <p
        class="baseName"
        v-if="!isNotMix(budgetList)"
      >
        <span class="lebel">预算：</span>
        <span>
          <el-button
            type="text"
            size="mini"
            @click="budgetMixShow = true"
            >混合值查看及编辑</el-button
          >
        </span>
      </p>
      <el-row v-if="isNotMix(budgetList)">
        <el-col :span="20">
          <budget
            :label-width="labelWidth"
            :budgetMsg="budgetMsg"
            @getBudgetMsg="getBudgetMsg"
            level="campaign"
            :num="currentSelect.length"
            @changeBidStrategy="changeBidStrategy"
            @changeCbo="changeCbo"
            @changeTarget="changeTarget"
            :copy="copy"
            ref="budget"
            @setBudget="setBudget"
            :params="params"
            :type="type"
          ></budget>
        </el-col>
        <el-col
          :span="2"
          v-if="currentSelect.length > 1"
          ><el-button
            type="text"
            size="mini"
            @click="budgetMixShow = true"
            class="mixBtn"
            >混合值查看及编辑</el-button
          ></el-col
        >
      </el-row>
    </div>
    <name-mix
      title="名称"
      :nameMsgList="nameMsgList"
      :nameMixShow.sync="nameMixShow"
      @setMixMsg="setMixMsg"
    ></name-mix>
    <status-mix
      title="状态"
      :statusMsgList="statusMsgList"
      :statusMixShow.sync="statusMixShow"
      @setMixMsg="setMixMsg"
    ></status-mix>
    <budget-mix
      title="预算"
      :budgetMixShow.sync="budgetMixShow"
      :budgetMsgList="budgetMsgList"
      @setMixMsg="setMixMsg"
      level="campaign"
    ></budget-mix>
  </div>
</template>
<script>
import name from './name';
import status from './status';
import budget from './budget';
import nameMix from './nameMix';
import statusMix from './statusMix';
import budgetMix from '../adset/budgetMix.vue';

export default {
  // currentLevel  代表复制组还是复制系列从哪个层级进来的
  // level 表示当前编辑是哪个层级
  // 复制广告组并创建新系列  修改系列信息时，level= adset  currentLevel = campaign
  //   type = draft表示从草稿箱进入
  props: ['currentSelect', 'baseData', 'updateList', 'labelWidth', 'copy', 'level', 'params', 'currentLevel', 'type'],
  data() {
    return {
      listData: [],
      nameList: [],
      statusList: [],
      budgetList: [],
      nameMsg: '',
      statusMsg: 'PAUSED',
      nameMixShow: false,
      statusMixShow: false,
      budgetMixShow: false,
    };
  },
  components: {
    name,
    status,
    nameMix,
    statusMix,
    budget,
    budgetMix,
  },
  computed: {
    budgetMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.updateList.map((m) => {
          if (m.id == v) {
            let budgetObj = {};
            // 主页
            budgetObj.name = m.name || '';
            budgetObj.id = m.id;
            budgetObj.is_cbo = m.is_cbo;
            budgetObj.lifetime_budget = m.lifetime_budget;
            budgetObj.daily_budget = m.daily_budget;
            budgetObj.optimization_goal = m.optimization_goal;
            list.push(budgetObj);
          }
        });
      });
      return list;
    },
    nameMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.updateList.map((m) => {
          if (m.id == v) {
            let nameObj = {};
            // 主页
            nameObj.name = m.name || '';
            nameObj.id = m.id;
            list.push(nameObj);
          }
        });
      });
      return list;
    },
    statusMsgList() {
      let list = [];
      this.currentSelect.map((v) => {
        this.updateList.map((m) => {
          if (m.id == v) {
            let statusObj = {};
            // 主页
            statusObj.status = m.status || '';
            statusObj.id = m.id;
            statusObj.name = m.name;
            list.push(statusObj);
          }
        });
      });
      return list;
    },
    budgetMsg() {
      return this.isNotMix(this.budgetList)
        ? this.budgetList[0]
        : { is_cbo: false, lifetime_budget: 0, daily_budget: 0 };
    },
  },
  methods: {
    emitData() {
      this.$emit('setCampaignData', this.listData);
    },
    // 判断是否是混合值
    isNotMix(list) {
      return !list.some((v) => {
        return JSON.stringify(v) != JSON.stringify(list[0]);
      });
    },
    setList() {
      let nameList = [],
        statusList = [],
        budgetList = [];
      this.currentSelect.map((v) => {
        this.listData.map((m) => {
          if (m.id == v) {
            nameList.push(m.name);
            statusList.push(m.status);
            budgetList.push({
              is_cbo: m.is_cbo,
              lifetime_budget: m.lifetime_budget,
              daily_budget: m.daily_budget,
              bid_strategy: m.bid_strategy,
              objective: m.objective,
              smart_promotion_type: m.smart_promotion_type,
              business: m.businessType,
              optimization_goal: m.optimization_goal,
            });
          }
        });
        this.nameList = nameList;
        this.statusList = statusList;
        this.budgetList = budgetList;
        console.log(statusList);
      });
    },
    setBaseMsg() {
      this.setNameMsg();
      this.setStatusMsg();
      // this.setBudgetMsg();
    },
    setNameMsg() {
      this.nameMsg = this.isNotMix(this.nameList) ? this.nameList[0] : '';
    },
    getNameMsg(val) {
      if (JSON.stringify(this.nameMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.name = val;
            this.$set(this.listData, i, m);
            this.$emit('setBaseName', 'campaign', v, val);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setBaseMsg();
      this.emitData();
    },
    setStatusMsg() {
      this.statusMsg = this.isNotMix(this.statusList) ? this.statusList[0] : 'PAUSED';
    },
    getStatusMsg(val) {
      if (JSON.stringify(this.statusMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m.status = val;
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setBaseMsg();
      this.emitData();
    },
    // setBudgetMsg(){
    //     this.budgetMsg = this.isNotMix(this.budgetList)?this.budgetList[0]:{is_cbo:false,lifetime_budget:0,daily_budget:0};
    // },
    getBudgetMsg(val) {
      console.log(val);
      if (JSON.stringify(this.budgetMsg) == JSON.stringify(val)) return;
      this.currentSelect.map((v) => {
        this.listData.map((m, i) => {
          if (m.id == v) {
            m = { ...m, ...val };
            this.$set(this.listData, i, m);
          }
        });
      });
      this.listData = JSON.parse(JSON.stringify(this.listData));
      this.setList();
      this.setBaseMsg();
      this.emitData();
    },
    setBudget(key, val) {
      this.$emit('setBudget', key, val);
    },
    setMixMsg(type, list) {
      if (!list) return;
      list.map((v) => {
        this.listData.map((k, i) => {
          if (v.id == k.id) {
            if (type == 'status') {
              k.status = v.status;
            }
            if (type == 'name') {
              k.name = v.name;
              this.$emit('setBaseName', 'campaign', v.id, v.name);
            }
            if (type == 'budget') {
              k.daily_budget = v.daily_budget;
              k.lifetime_budget = v.lifetime_budget;
            }
          }
          this.$set(this.listData, i, k);
        });
      });
      this.setList();
      this.emitData();
      if (type == 'status') this.setStatusMsg();
      if (type == 'name') this.setNameMsg();
      if (type == 'budget');
    },
    // 改变广告系列出价方式
    changeBidStrategy(v) {
      this.$emit('setBidStrategy', v);
      this.setList();
      this.setBaseMsg();
    },
    // 改变是否开启系列预算优化
    changeCbo(v) {
      console.log('changeCbochangeCbochangeCbo');
      this.$emit('setCbo', v);
      this.setList();
      this.setBaseMsg();
    },
    changeTarget(v) {
      this.$emit('setTarget', v);
      this.setList();
      this.setBaseMsg();
    },
  },
  watch: {
    currentSelect: {
      handler() {
        this.setList();
        this.setBaseMsg();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.listData = JSON.parse(JSON.stringify(this.updateList));
    this.setList();
    this.setBaseMsg();
  },
};
</script>
<style lang="scss" scoped></style>
