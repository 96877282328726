<template>
    <div>
        <el-form :model="nameMsg" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="名称：" prop="page" class="pageSelect" require>
                <el-input palce="广告名称" type="text" v-model="nameMsg.name" @change="setName"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:['nameInfo'],
    data() {
        return {
            nameMsg:{name:this.nameInfo}
        }
    },
    methods: {
        setName(v){
            this.$emit('getNameMsg',v)
        }
    },
    watch: {
        nameInfo:{
            handler(){
                this.nameMsg.name = this.nameInfo
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>